export const Introduction = {
  office: {
    text: [
      "ホームページをご覧いただきありがとうございます。",
      "当事務所は平成21年に開業して10年余、多くの方々の税務申告をさせていただいております。中小企業の営業と生活をまもるために、憲法にもとづく納税者の権利を擁護する立場で税務と会計の業務をおこなうよう努めております。",
      "関与先企業の多様なニーズに応えるべく、「共に経営を見つめあい事業繁栄を支援する信頼の絆（スクラムパートナー）」を使命感として、当事務所の業務ノウハウをあらゆる分野から集積、合理化し、今日まで至っております。",
      "激変する経営環境の中で、貴社がゆたかな繁栄を続けるために、当事務所は最新のコンピューターを活用し、経営体質と高付加価値経営の実現を使命として、的確かつ迅速なアドバイスを行います。",
      "「顧客第一主義」の精神をモットーに、所員共々、より一層の研鑽を重ね、経営者のよきパートナーとして、企業の繁栄に貢献いたします。",
    ],
  },
  self: {
    name: "税理士 木下良",
    text: [
      "誰もが知っていながら、よく分かっていないのが税金ではないでしょうか。",
      "サラリーマンでも給料天引きの所得税から、住民税、自宅にかかる固定資産税から、買い物に行けば消費税、領収書には印紙税などなど… 身の回りにはいろいろな税が存在します。",
      "ましてや、会社を経営しようとすると、税務署、都税事務所や市町村役場などへの届出から、税務申告、毎月の源泉所得税の計算と納付、年末には年末調整などなど…",
      "せっかく独立開業したのに、苦手な経理や難しい税金の計算に悩まれている方は、ぜひ一度ご相談ください。",
    ],
  },
}

export const Works = {
  enterprise: {
    name: "法人のお客様",
    list: [
      "顧問料で相談したい",
      "帳簿の記帳と申告を依頼したい",
      "申告だけ依頼したい",
      "税務調査の立ち合いを依頼したい",
      "納税の相談にのってほしい",
    ],
  },
  personal: {
    name: "個人のお客様",
    list: [
      "会社を設立したい",
      "確定申告を依頼したい",
      "税務調査の依頼をしたい",
    ],
  },
}

export const Titles = {
  news: { title: "最新情報" },
  about: {
    title: "事務所方針",
    subtitle: "経営者のよきパートナーとして",
  },
  introduction: {
    title: "税理士紹介",
    subtitle: "税理士より挨拶",
  },
  works: {
    title: "業務内容",
    subtitle: "実際の一例",
  },
  blog: {
    title: "ブログ",
    subtitle: "随時更新",
  },
}
