import { graphql, Link, Page, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import {
  Article,
  Headings,
  Section,
  Typography,
  Animation,
  Wrapper
} from "@/atoms"
import {
  BlogCard,
  Card,
  Carousel,
  Layout,
  NewsListing,
  NextSEO
} from "@/components"
import { IndexPageQuery } from "@/gatsby-graphql"
import { timeFromNow } from "@/utils/index"
import { Introduction as introduction, Titles as titles, Works as works } from "@/constants/text";
import useWindowDimensions from "@/utils/hooks"
import { Size } from "@/constants/index"

const IndexPage: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  const newsEdges = data.allWpNews.edges
  const postsEdges = data.allWpPost.edges
  const categoryNodes = data.allWpCategory.nodes
  const { width: windowWidth } = useWindowDimensions()
  const [rootMargin, setRootMargin] = useState('-100px')

  useEffect(() => {
    if (windowWidth >= parseInt(Size.tablet)) {
      setRootMargin('-300px')
    } else {
      setRootMargin('-100px')
    }
    return () => { }
  }, [windowWidth])

  return (
    <Layout showTwitter>
      <NextSEO
        type="Article"
        title="ホーム"
        description="当事務所は、平成21年に開業して10年余、多くの方々の税務申告をさせていただいております。中小企業の営業と生活をまもるために、憲法にもとづく納税者の権利を擁護する立場で税務と会計の業務をおこなうよう努めております。"
        url=""
        breadCrumbs={[{ 'title': 'トップページ' }]}
      />
      <Section css={{ ...tw`` }}>
        <Carousel />
      </Section>
      <Article css={{
        ...tw`space-y-0`,
        '@laptop': {
          ...tw`space-y-0`
        },
        [`& ${Wrapper}`]: {
          ...tw`py-12`,
          '@laptop': {
            ...tw`py-24`
          }
        },
      }} >
        {newsEdges.length > 0 && (
          <Section >
            <Wrapper
              // center
              maxWidth={'6xl'}
              flex='col'
              px={4}
              py={6}
              css={{ ...tw`space-y-4 mx-auto`, '@laptop': { ...tw`flex-row` } }}
            >
              <header css={{
                ...tw`text-left`,
                '@tablet': { ...tw`text-center w-1/2 my-auto` }
              }}>
                <Headings variant="h2" as='h1' ><Link to="/news">{titles.news.title}</Link> </Headings>
              </header>
              <div css={{ ...tw`space-y-2`, "@mobile": { ...tw`text-center` }, "@tablet": { ...tw`text-left w-1/2` } }}>
                <NewsListing newsEdges={newsEdges} />
              </div>
            </Wrapper>
          </Section>
        )}
        <Section bg='secondary' >
          <Animation rootMargin={rootMargin}>
            <Wrapper
              maxWidth={'6xl'}
              px={4}
              py={6}
              css={{ ...tw`space-y-8`, '@laptop': { ...tw`space-y-16` } }}
            >
              <header css={{ '@laptop': { ...tw`text-center` } }}>
                <Headings variant="h2" as='h1'><Link to="/policy">{titles.about.title}</Link></Headings>
                <Headings variant="h5" as='h2' css={{ color: '$text-tertiary' }}>{titles.about.subtitle}</Headings>
              </header>
              <article css={{
                ...tw`space-y-6`,
                '@laptop': { ...tw`flex flex-row-reverse space-y-0` }
              }}>
                <div css={{
                  ...tw`flex flex-row space-x-4 mx-auto`,
                  "@tablet": {
                    ...tw`space-x-6 w-3/5`
                  },
                  "@laptop": {
                    ...tw`space-x-0 space-y-6 mx-auto w-1/3 flex-col`
                  }
                }}>
                  <GatsbyImage
                    image={data.image1.childImageSharp.gatsbyImageData}
                    alt="top image1"
                    imgStyle={{
                      ...tw`rounded-2xl max-h-60`,
                      '@laptop': {
                        ...tw`w-auto`
                      }
                    }}
                  />
                  <GatsbyImage
                    image={data.image2.childImageSharp.gatsbyImageData}
                    alt="top image2"
                    imgStyle={{
                      ...tw`rounded-2xl max-h-60`,
                      '@laptop': {
                        ...tw`w-auto`
                      }
                    }}
                  />
                </div>
                <Typography prose='base' css={{ ...tw`mx-auto`, '@laptop': { ...tw`my-auto! w-1/2` } }} >
                  {introduction.office.text.map((ele, i) => <p key={i}>{ele}</p>)}
                </Typography>
              </article>
            </Wrapper>
          </Animation>
        </Section>
        <Section >
          <Animation rootMargin={rootMargin}>
            <Wrapper
              maxWidth={'6xl'}
              px={4}
              py={6}
              css={{ ...tw`space-y-8`, '@laptop': { ...tw`space-y-16` } }}
            >
              <header css={{ '@laptop': { ...tw`text-center` } }}>
                <Headings variant="h2" as='h1'><Link to="/about">{titles.introduction.title}</Link></Headings>
                <Headings variant="h5" as='h2'> {titles.introduction.subtitle} </Headings>
              </header>
              <article
                css={{
                  ...tw`flex flex-col space-y-4`,
                  "@tablet": {
                    ...tw`flex flex-row space-y-0`
                  },
                }}
              >
                <div css={{ ...tw`flex flex-col space-y-2 items-center`, '@laptop': { ...tw`mx-auto` } }}>
                  <div css={{ ...tw`w-1/2` }}>
                    <GatsbyImage
                      image={data.profile.childImageSharp.gatsbyImageData}
                      alt="profile"
                      imgStyle={{ borderRadius: '50%' }}
                    />
                  </div>
                  <span css={{ ...tw`text-center` }}>{introduction.self.name}</span>
                </div>
                <Typography prose='base' css={{ '@laptop': { ...tw`my-auto!` } }} >
                  {introduction.self.text.map((ele, i) => <p key={i}>{ele}</p>)}
                </Typography>
              </article>
            </Wrapper>
          </Animation>
        </Section>
        <Section bg='tertiary' >
          <Animation rootMargin={rootMargin}>
            <Wrapper
              maxWidth={'6xl'}
              px={4}
              py={6}
              css={{ ...tw`space-y-8`, '@laptop': { ...tw`space-y-16` } }}
            >
              <header css={{ '@laptop': { ...tw`text-center` } }}>
                <Headings variant="h2" as='h1'><Link to="/work">{titles.works.title}</Link></Headings>
                <Headings variant="h5" as='h2'>{titles.works.subtitle}</Headings>
              </header>
              <div css={{ ...tw`space-y-6`, '@tablet': { ...tw`flex flex-row space-y-0 w-full justify-center space-x-6` } }}>
                {Object.values(works).map((ele, i) => {
                  return (
                    <Card
                      key={i}
                      title={<Headings variant="h3" css={{ ...tw`mb-6` }}>{ele.name}</Headings>}
                      wrapperProps={{}}
                    >
                      <ul tw="list-disc list-inside space-y-2">
                        {ele.list.map((ele, i) => <li key={i}>{ele}</li>)}
                      </ul>
                    </Card>
                  )
                })}
              </div>
            </Wrapper>
          </Animation>
        </Section>
        {
          postsEdges.length > 0 && (
            <Section >
              <Wrapper
                maxWidth={'6xl'}
                px={4}
                py={6}
                css={{ ...tw`space-y-8`, '@laptop': { ...tw`space-y-16` } }}
              >

                <header css={{ '@laptop': { ...tw`text-center` } }}>
                  <Headings variant="h2" as='h1'><Link to="/blog">{titles.blog.title}</Link></Headings>
                  <Headings variant="h5" as='h2'>{titles.blog.subtitle}</Headings>
                </header>
                <div css={{ ...tw`flex flex-row items-center justify-center` }}>
                  {categoryNodes.map((node, outer_index) => {
                    const filteredEdges = postsEdges.filter(
                      edge =>
                        edge.node.categories.nodes[0].databaseId ===
                        node.databaseId
                    )
                    // show at most 2 posts
                    return filteredEdges.length > 0 ? (
                      <React.Fragment key={outer_index}>
                        {filteredEdges
                          .slice(0, Math.min(filteredEdges.length, 2))
                          .map((edge, inner_index) => {
                            return (
                              <BlogCard
                                key={inner_index}
                                title={edge.node.title}
                                text={edge.node.content}
                                date={timeFromNow(edge.node.date)}
                                path={`/blog/${decodeURIComponent(
                                  edge.node.slug
                                )}`}
                              />
                            )
                          })}
                      </React.Fragment>
                    ) : null
                  })}
                </div>
              </Wrapper>
            </Section>
          )
        }
      </Article>
    </Layout >
  )
}

export default IndexPage

export const data = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
      }
    }
    allWpNews(sort: { fields: news___newsDate, order: DESC }, limit: 3) {
      edges {
        node {
          databaseId
          news {
            newsDate
            newsSlug
            newsTitle
            newsText
            newsCategoryAcf {
              name
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: date }, limit: 10) {
      edges {
        node {
          date
          modified
          databaseId
          excerpt
          uri
          slug
          title
          content
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              databaseId
              uri
              count
              name
              slug
            }
          }
        }
      }
    }
    allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        databaseId
        uri
        count
        name
        slug
      }
    }
    image1: file(relativePath: { eq: "image1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          formats: [WEBP]
          placeholder: BLURRED
        )
      }
    }
    image2: file(relativePath: { eq: "image2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          formats: [WEBP]
          placeholder: BLURRED
        )
      }
    }
    profile: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          formats: [WEBP]
          placeholder: BLURRED
        )
      }
    }
  }
`